<template>
  <v-footer
    dark
    class="ctk-footer"
  >
    <template>
      <v-container grid-list-md text-center pt-5>
        <v-layout wrap>
          <!--<v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">公司介绍</h5>
              <div class="text-left ctk-sub-name"><a href="/about">德硅机械</a></div>
            </section>
          </v-flex>-->
          <v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">产品系统</h5>
              <ul class="ctk-footer-link text-left" v-if="info.product">
                <li v-for="cat in info.product.category.cn" :key="cat.id"><a v-bind:href="'/products/' + cat.id">{{cat.name}}</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">合作伙伴</h5>
              <ul class="ctk-footer-link text-left">
                <li v-for="link in info.link"  :key="link.id"><a v-bind:href="'/partner/' + link.id">{{link.title}}</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">公司动态</h5>
              <div class="text-left ctk-sub-name"><a href="/news">公司动态</a></div>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md2 lg2 pl-2 class="hidden-sm-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">联系我们</h5>
              <ul class="ctk-footer-link text-left">
                <li><a href="/contact">联系我们</a></li>
                <li><a href="/message">在线留言</a></li>
              </ul>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md4 lg4 pl-2 class="hidden-sm-and-down">
            <h5 class="pb-3 text-left ctk-footer-name">{{this.$t("footer.contacts")}}</h5>
            <ul class="ctk-footer-link text-left">
              <li>
                <span>{{this.$t("footer.telephone")}}：</span><span>{{info.tel}}</span>
              </li>
              <li>
                <span>{{this.$t("footer.email")}}：</span><span>{{info.email}}</span>
              </li>
              <li>
                <span>{{this.$t("footer.address")}}：</span><span>{{info.addr}}</span>
              </li>
            </ul>
          </v-flex>
          <v-flex xs12 class="ctk-footer-copyright">
            &copy;2019 Citiking Machinery All Rights Reserved | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号: {{info.icp}}</a>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  }
}
</script>
